.public-page-content {
    
}

.suspense-preloader {
    position: relative;
    width: 100vh;
    height: 100vh;
    background-color: $default;
    color: #fff;
}

.admin-header-divider {
    width: 100%;
    background-color: $primary;
}

.main-content.customer-layout {
    background-color: #fff;
}

.banner-image {
    img {
        width: 100%;
    }
}

.custom-toast {
    margin-top: 7px;
    min-width: 300px;
}

.mobile-sidebar-avatar {
    display: block;
    background-color: #11B5EF;
    padding-top: 8px;
    width: 40px;
    height: 40px;
    color: #fff;
    border-radius: 50%;
    position:relative;
    text-align: center;
}

button.btn.has-icon {
    svg {
        margin-right: 0.675rem;
    }
}

.table-search-input {
    
}

.contacts-list {
    b {
        font-weight: 500;
    }

    svg {
        width: 1em !important;
    }
}

.table-sort-button {
    background: none;
    border: none;
    box-shadow: none;
}

.product-image-upload {
    position:relative;
    width: 85px;
    .text-overlay {
        display:none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom:0;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        color: #000;
        padding-top: 30px;

    }

    &:hover {
        cursor: pointer;
        .text-overlay {
            z-index: 999;
            display: inline-block;
            background: rgba(255, 255, 255, 0.5);
        }
    }
}

.product-img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    background-color: #fff;
}
.list-product-img {
    width: 85px;
    height: 85px;
    object-fit: cover;
}

.language-switcher, .currency-switcher {
    list-style: none;
    padding:0;

    li {
        display: inline-block;
        margin-right: 10px;
        cursor: pointer;

        .currency-text {
            padding: 0 2px;
            background-color: #fff;
            border: 1px solid #ccc;
        }

        &.li-label {
            cursor: unset;
        }
        &.active {
            cursor: unset;
            img {
                filter: alpha(opacity=100);
                opacity: 1;
            }

            .chevron {
                filter: alpha(opacity=100);
                opacity: 1;
            }
        }

        .chevron {
            color: $primary;
            display: block;
            text-align: center;
            filter: alpha(opacity=0);
            opacity: 0;
        }

        img {
            height: 20px;
            width: auto;
            filter: alpha(opacity=50);
            opacity: 0.5;
            
        }
    }
}

.cookie-pref-table {
    tr {
        td {
            vertical-align: middle;
        }
        td:first-child {
            font-weight: bold;
        }
    }
}

.carousel { 
    &.hide-arrows {
        .carousel-control-prev, .carousel-control-next {
            display: none;
        }
    }
    
}

.homepage {
    h1 {
        font-size: 40px;
        margin-bottom: 30px;
        border-bottom: 4px solid $primary;
        display: inline-block;
    }

    h2 {
        font-size: 40px;
        margin-bottom: 30px;
        border-bottom: 4px solid $primary;
        display: inline-block;
    }

    h3 {
        font-size: 30px;
        margin-bottom: 20px;
        color: $default;
        border-bottom: 4px solid $accent;
        display: inline-block;
    }
}

ul.collapse-group {
    li {
        border: none;
        padding: 0;
        border-left: 5px solid $accent;
        border-bottom: 1px solid $accent;
        border-radius: 0 !important;
        margin-top: 10px;
    }
    .list-group-item-heading, .list-group-item-text {
        padding: 10px 20px;
    }

    .list-group-item-heading {
        cursor: pointer;
        font-size: 1rem;
    }
    .list-group-item-text {

    }
}

.card-stats {
    height: 100%;
}

.react-multi-carousel-list {
    /* background: url("http://localhost:8080/public/vta3.svg");
    background-repeat: repeat;
    background-size: 150px 150px; */
    padding: 15px 0;
}

.light-background-card {
    background: rgba(255, 255, 255, 0.8);
}

.react-multi-carousel-item {
    padding-right: 5px;
    padding-left: 5px;

    .multi-carousel-slide-item {
        position: relative;
        height: 250px;
        background: rgba(255, 255, 255, 0.8);
        cursor: pointer;
        padding: 0;
        color: #3c3c3c;
        border-radius: 7px;

        &.active {
            
            .slide-content .slide-caption-text {
                color: $accent !important;
            }
        }

        .slide-content {
            height: 100%;
            position:relative;
            display:block;
            overflow: hidden;
            border-radius: 7px;
            img {
                width: 100%;
                height: auto;
            }
            .slide-caption-text {
                display: block;
                position: absolute;
                width: 100%;
                bottom: 0;
                padding: 10px 0;
                background: rgba(0, 0, 0, 0.6);
                color: #fff;
                font-weight: bold;
            }
        }
    }
}

.homepage .container {
    padding-top: 50px;
    padding-bottom: 50px;
}
.accent-pattern-bg {
    background-color: $accent;
    background-image: url("../img/asset.png");
    background-position: 0 100%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
}
@media only screen and (max-width: 600px) {
    .accent-pattern-bg {
        background-size: auto 100%;
    }
}
.homepage-section-background {
    padding-bottom: 25px;
}

.unstyled-list {
    padding:0;
    list-style: none;
}

.footer-section {
    padding-bottom: 30px;
    padding-top: 30px;
    h4 {
        margin-bottom: 25px;
    }
    &.white-text {
        color: #eee;

        h4 {
            color: #fff;
        }

        svg {
            color: #fff;
        }
    }
}

.footer-social-links {
    ul {
        li {
            display: inline-block;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            button {
                padding:0;
            }
            
            svg {
                font-size: 2rem;
                color: #000;
            }
        }
    }
}

.credits-list {
    font-size: 12px;
}

.pre-footer-banner {
    position: relative;
    width: 100%;
    display: block;
    
    &.default {
        background-color: $default;
    }

    &.accent {
        background-color: $accent;
    }

    &.primary {
        background-color: $primary;
    }

    img {
        width: 100%;
        object-fit: cover;
        mix-blend-mode: soft-light;
    }

    .banner-content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &.light {
            color: #fff;
        }

        &.dark {
            color: #3c3c3c;
        }
        
        p {
            margin:0;
            
            &.footer-banner-heading {
                line-height: 1;
                font-size: 2rem;
                font-weight: bold;
            }
        }
    }
}

.show-desktop {
    display: initial;
}
.show-mobile {
    display: none;
}

@media only screen and (max-width: 600px) {

    .show-desktop {
        display: none;
    }
    .show-mobile {
        display: initial;
    }
    .modal {
        top: 95px;
    }
    table.cookie-pref-table {
        &.table td, &.table th {
            white-space: normal !important;
        }
    }
  }
@media only screen and (min-width: 601px) {

    .carousel-control-next {
        right: -110px !important;
    }
    
    .carousel-control-prev {
        left: -110px !important;
    }

    .sticky-header .modal-dialog {
        top: 90px !important;
    }
  }


.list-group-item {
    

    .badge {
        color: #3c3c3c;
    }

    &.active {
        .badge {
            color: #fff;
        }
    }
}

.preloader {
    position: fixed;
    left:0;
    top:0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.1);

    .spinner {
        position: absolute;
        top: 49%;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

.table thead th {
    vertical-align: middle !important;
}

.back-to-top-button {
    position: fixed;
    left:0;
    right:0;
    margin: 0 auto;
    bottom: 25px;
    height: 45px;
    width: 45px;
    border-radius: 100%;
    padding: 0;
    z-index: 9999;
}

.logout-sidebar {
    .nav-item {
        a {
            background-color: $primary;
            color: #fff !important;

            &:hover {
                .ni {
                    color: $accent;
                }
            }
        }
    }
    
}


@media only screen and (min-height: 725px) {

    .logout-sidebar {
        position: absolute;
        bottom: 0;
        .nav-item {
            background: $default;
            padding-bottom: 20px;
        }
    }
}

table.total-price-table {
    thead th {
        background: #eee;
    }

    thead tr.total-row th {
        font-size: 1rem;
        text-transform: none;
    }
}

.navbar-brand img {
    -webkit-transition: 0.1s;
        transition: 0.1s;
}
.smaller-nav {
    .navbar-brand img {
        height: 3.5rem;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }
}

.card.user-product-card {
    height: 100%;
    padding-bottom:0;

    .card-body {
        padding-bottom: 80px;
        position: relative;

        .select-product-button-wrapper {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 15px;
            width: 100%;
        }
    }
    
}